<template>
  <div class="app__login">
    <div class="app__login--lk">
      <h1>Личный кабинет водителя переехал!</h1>
      <img src="../assets/images/auth-img.png" alt="" />
      <p>
        Мы перенесли личный кабинет водителя в удобное приложение
        диспетчерская.su для&nbsp;Android
      </p>

      <div class="button__yellow big">
        <a
          href="https://диспетчерская.su/app-manual"
          target="_blank"
          title="СКАЧАТЬ ПРИЛОЖЕНИЕ"
          :disabled="disabled"
          >СКАЧАТЬ ПРИЛОЖЕНИЕ</a
        >
      </div>
    </div>
    <ui-message
      @messageClose="messageClose"
      :message="message"
      :messageProp="messageProp"
    />
  </div>
</template>

<script>
import UiMessage from "../components/ui/ui-message.vue";
// MaskedInput
// mport MaskedInput from "vue-masked-input";

import { mapActions } from "vuex";

export default {
  name: "Auth",

  components: {
    UiMessage
    // MaskedInput
  },

  data() {
    return {
      username: "",
      password: "",
      isInvalidPassword: false,
      isInvalidUsername: false,
      disabled: false,
      message: false,
      messageProp: {}
    };
  },

  computed: {
    clearLogin() {
      let clearLogin = "";
      if (this.username) {
        clearLogin = this.username.replace(/[^0-9]/g, "");
      }
      return clearLogin;
    }
  },

  methods: {
    ...mapActions(["GET_TOKEN", "GET_TOKEN_REFRESH"]),

    clearForm() {
      this.username = "";
      this.password = "";
      this.isInvalidPassword = false;
      this.isInvalidUsername = false;
      this.disabled = false;
    },

    formIsValid() {
      let isValid = true;
      if (this.username.length === 0 || this.password.length === 0) {
        isValid = false;
      }
      return isValid;
    },

    authHandler() {
      if (this.formIsValid) {
        this.disabled = true;
        this.axios
          .post("/api/login_check", {
            // username: this.clearLogin,
            username: this.username,
            password: this.password
          })
          .then((response) => {
            this.GET_TOKEN(response.data.token);
            // localStorage.setItem("token", response.data.token);
            this.GET_TOKEN_REFRESH(response.data.refresh_token);
            // localStorage.setItem("refreshToken", response.data.refresh_token);
            this.$router.push("/");
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status === 401) {
              (this.message = true),
                (this.messageProp = {
                  class: "alert__danger",
                  title: "Неверные данные для входа",
                  text: "Попробуйте ввести заново"
                });
            }
          })
          .finally(() => {
            this.clearForm();
          });
      }
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>

<style lang="scss">
.button__yellow {
  display: flex;
  align-items: center;
  background: $color-yellow;
  cursor: pointer;
  a {
    color: $color-black;
  }
  &.big {
    @include button;
    @include font-family-montserrat;
    font-size: 30px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    height: 84px;
    padding: 0 50px;
    @media (max-width: 1199.98px) {
      font-size: 30px;
      line-height: 35px;
    }
    @media (max-width: 767.98px) {
      font-size: 25px;
      line-height: 30px;
      height: 80px;
      padding: 0 20px;
    }
    @media (max-width: 575.98px) {
      font-size: 21px;
      line-height: 24px;
      height: 54px;
      padding: 0 20px;
    }
  }

  &:hover {
    background: $color-yellow-hover;
  }
}
</style>
